import { G } from '@mobily/ts-belt';
import cookie from 'browser-cookies';
import dayjs from 'dayjs';
import { z } from 'zod';
import { type AuthenticatedClient } from '@stitch-fix/sf-next';
import { type FlowId, flows } from './flows';
import type {
  FixAutoShipInterval,
  FixScheduleInput,
} from '../../../../types/definitions/vendor/graphql-schema.d';

export type FixScheduleInputData = Pick<
  FixScheduleInput,
  'clientWantsFixOn' | 'firstAvailableFixDate'
> & {
  interval: FixAutoShipInterval;
};

export const CADENCE_COOKIE_NAME = 'cob_lff_cadence';

export const getStepIndex = (flow: FlowId, step: string) =>
  flows[flow].findIndex(f => f.step === step);

const intervalSchema: z.ZodType<FixAutoShipInterval> = z.union([
  z.literal('EVERY_MONTH'),
  z.literal('EVERY_OTHER_MONTH'),
  z.literal('EVERY_THREE_MONTHS'),
  z.literal('EVERY_TWO_THREE_WEEKS'),
  z.literal('NONE'),
]);

const cadenceSchema = z.object({
  interval: intervalSchema.optional(),
  clientWantsFixOn: z.string().optional(),
  firstAvailableFixDate: z.string().optional(),
});

export const persistStepData = (data: Partial<FixScheduleInputData> = {}) => {
  cookie.set(
    CADENCE_COOKIE_NAME,
    JSON.stringify({
      interval: data?.interval,
      clientWantsFixOn: data?.clientWantsFixOn,
      firstAvailableFixDate: data?.firstAvailableFixDate,
    }),
    { expires: dayjs().add(1, 'hour').toDate() },
  );
};

export const getPersistedStepData = (
  requestCookies: Partial<Record<string, string>> = {},
) => {
  try {
    return cadenceSchema.parse(
      JSON.parse(
        decodeURIComponent(requestCookies[CADENCE_COOKIE_NAME] ?? '{}'),
      ),
    );
  } catch (e) {
    return {};
  }
};

type GetFirstAvailableStepIndexParams = {
  businessLine: AuthenticatedClient['businessLine'];
  flow: FlowId;
};

export const getFirstAvailableStepIndex = ({
  businessLine,
  flow,
}: GetFirstAvailableStepIndexParams) => {
  const activeFlow = flows[flow];

  return activeFlow.findIndex(
    f =>
      f.restrictedBusinessLines === undefined ||
      f.restrictedBusinessLines?.includes(businessLine) === true,
  );
};

/**
 * Builds the router path from a flow and identifier
 * @param flow flowId
 * @param identifier of the step, as an index of the flow or a stepId name
 * @param fallback the path returned if identifier does not exist in flow
 * @returns the path for the flow and step identifier
 */
export const getStepPath = ({
  flow,
  identifier,
  fallback = '/',
  businessLine,
}: {
  flow: FlowId;
  identifier: number | string;
  fallback?: string;
  businessLine?: AuthenticatedClient['businessLine'];
}) => {
  const step = G.isString(identifier)
    ? flows[flow].find(f => f.step === identifier)
    : flows[flow]?.[identifier];

  if (
    G.isUndefined(step) ||
    (businessLine &&
      step.restrictedBusinessLines &&
      !step.restrictedBusinessLines.includes(businessLine))
  ) {
    return fallback;
  }

  return `/onboarding/get-your-fix/${flow}/${step.step}`;
};

export const getSteps = ({
  flow,
  currentStepIndex,
  businessLine,
}: {
  businessLine: AuthenticatedClient['businessLine'];
  flow: FlowId;
  currentStepIndex: number;
}) => ({
  currentStepIndex,
  nextStepPath: getStepPath({
    flow,
    identifier: currentStepIndex + 1,
    businessLine,
  }),
  previousStepPath: getStepPath({
    flow,
    identifier: currentStepIndex - 1,
    fallback: '/onboarding/profile-note',
    businessLine,
  }),
});
